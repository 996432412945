import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {IE3BorderTransportMeans, IE3CL750, IE3ConsignmentProcess} from "@portbase/bezoekschip-service-typescriptmodels";
import {TableField, TableViewComponent} from "../../../../../common/table/table-view/table-view.component";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {cloneDeep} from "lodash";
import typesOfMeansOfTransport from "./types-of-means-of-transport.json";

@Component({
  selector: 'app-consignment-vehicles-table',
  templateUrl: './consignment-vehicles-table.component.html',
  styleUrls: ['./consignment-vehicles-table.component.scss']
})
export class ConsignmentVehiclesTableComponent implements AfterViewInit {
  tableView = TableViewComponent;
  portVisitUtils = PortvisitUtils;
  typeOfIdentifications: IE3CL750[] = [IE3CL750.N20, IE3CL750.N21, IE3CL750.N30, IE3CL750.N31, IE3CL750.N41, IE3CL750.N80];

  @Input() data: IE3BorderTransportMeans[];
  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() editable: boolean = true;
  @Input() editMode: boolean;

  allTypeOfMeansOfTransportKeys = Object.keys(typesOfMeansOfTransport);

  term: string;
  fieldsDefinition: TableField[] = [];
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataChange: EventEmitter<IE3BorderTransportMeans[]> = new EventEmitter<IE3BorderTransportMeans[]>();

  @ViewChild("actionColumnRef") actionColumnRef: TemplateRef<any>;
  @ViewChild("nationalityRef") nationalityRef: TemplateRef<any>;
  @ViewChild("identificationTypeRef") identificationTypeRef: TemplateRef<any>;
  @ViewChild("typeOfMeansOfTransportRef") typeOfMeansOfTransportRef: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.fieldsDefinition = [
      {
        type: "string",
        header: "Identification type",
        name: "typeOfIdentification",
        editable: true,
        formatter: this.typeOfIdentificationFormatter,
        editTemplate: this.identificationTypeRef
      }, {
        type: "string",
        header: "Description",
        editable: true,
        name: "typeOfMeansOfTransport",
        formatter: this.typeOfMeansOfTransportFormatter,
        editTemplate: this.typeOfMeansOfTransportRef
      }, {
        type: "string",
        header: "Identification",
        editable: true,
        name: "identificationNumber"
      }, {
        type: "string",
        header: "Registration nationality",
        editable: true,
        name: "nationality",
        editTemplate: this.nationalityRef,
        formatter: PortvisitUtils.countryFormatter
      }, {
        type: "action",
        name: "actionColumn",
        template: this.actionColumnRef,
        cellClass: "p-1 auto-width"
      }
    ]
  }

  addRecord = () => {
    const data = cloneDeep(this.data);
    data.push({});
    this.data = data;
    this.editModeChange.emit(true);
    this.dataChange.emit(this.data);
  }

  deleteRecord = (record: IE3BorderTransportMeans) => {
    this.data = this.data.filter(d => d.identificationNumber !== record.identificationNumber);
    this.dataChange.emit(this.data);
  }

  typeOfMeansOfTransportFormatter = (code: string) => code && typesOfMeansOfTransport[code]
      ? `${code} - ${typesOfMeansOfTransport[code]}` : "";

  typeOfIdentificationFormatter = (value: IE3CL750) => {
    switch (value) {
      case IE3CL750.N20:
        return "Wagon Number";
      case IE3CL750.N21:
        return "Train Number";
      case IE3CL750.N30:
        return "Registration Number of the Road Vehicle";
      case IE3CL750.N31:
        return "Registration Number of the Road Trailer";
      case IE3CL750.N41:
        return "Registration Number of the Aircraft";
      case IE3CL750.N80:
        return "European Vessel Identification Number (ENI Code)";
    }
  }
}
