<div class="row gx-0 px-3 py-2 border-bottom">
  <div class="col col-lg-6 d-flex align-items-center" style="max-width: 320px;">
    <app-local-filter (termChanged)="term = $event; termUpdated.emit($event)" class="w-100"
                      (updated)="updateFiltered($event)" [data]="_data"
                      [placeholder]="'Search'"></app-local-filter>
  </div>
  <div class="col-auto ms-auto">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <button *ngIf="editable" class="btn btn-outline-primary text-decoration-none" (click)="addButtonClicked.emit()">
      <i class="button-icon fa-light fa-plus"></i>
      <span class="ps-2 d-none d-md-inline">{{addText}}</span>
    </button>
  </div>
</div>
<table class="table align-middle">
  <thead class="table-light">
    <tr>
      <ng-container *ngFor="let field of enabledFields; index as i; trackBy: trackByName">
        <ng-container *ngIf="field.header else noHeader">
          <th scope="col" (click)="sort(field)">
            <span class="sub-text fw-normal">{{field.header}}</span>
            <i class="fa fa-fw fa-light sub-text" [ngClass]="(sortOrder === 'asc' ? 'fa-arrow-down' : 'fa-arrow-up') + (sortField === field ? ' visible ' : ' invisible ')"></i>
          </th>
        </ng-container>
        <ng-template #noHeader>
          <th scope="col" class="auto-width">

          </th>
        </ng-template>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let record of filteredData | orderBy: sortField : sortOrder; trackBy: recordTrackBy">
      <ng-container *ngFor="let field of enabledFields; index as i; trackBy: trackByName">
        <td [ngClass]="field.cellClass">
          <ng-container *ngIf="field.template else plainValue">
            <ng-container [ngTemplateOutlet]="field.template" [ngTemplateOutletContext]="{value: getValueOfRecord(record, field)}"></ng-container>
          </ng-container>
          <ng-template #plainValue>
            <ng-container *ngIf="field.editable && editMode else readMode">
              <ng-container *ngIf="field.editTemplate else inputField">
                <ng-container [ngTemplateOutlet]="field.editTemplate" [ngTemplateOutletContext]="{value: record, field: field}"></ng-container>
              </ng-container>
              <ng-template #inputField>
                <input class="form-control w-100 h-100" [ngModel]="getValueOfRecord(record, field)"
                       (ngModelChange)="tableView.onFieldChanged(record, field, $event)" [required]="field.required"/>
              </ng-template>
            </ng-container>
            <ng-template #readMode>
              <app-highlight [term]="term" [result]="getValueOfRecord(record, field)"></app-highlight>
            </ng-template>
          </ng-template>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
