import {Component, Input} from '@angular/core';
import {IE3GoodsItemSummary} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-consignment-goods-column',
  templateUrl: './consignment-goods-column.component.html',
  styleUrls: ['./consignment-goods-column.component.scss']
})
export class ConsignmentGoodsColumnComponent {
  @Input() term: string;
  @Input() goods: IE3GoodsItemSummary[];
  @Input() ellipsisCharacterCount: number = undefined;

  trackByGoodsItem = (index: number, record: IE3GoodsItemSummary) => record.goodsItemNumber;

  goodsAsText = (goodsItem: IE3GoodsItemSummary) => {
    const commodityText = (goodsItem.commodity.commodityCode?.harmonizedSystemSubHeadingCode || '') + (goodsItem.commodity.commodityCode?.combinedNomenclatureCode || '');
    return `${commodityText.length ? commodityText + ' - ' : ''}${goodsItem.commodity.descriptionOfGoods}`;
  }
}
