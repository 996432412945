import {Component, ElementRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
  Country,
  IE3AddressBook,
  IE3CL707,
  IE3Party,
  IE3UpsertPartyInAddressBook
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {ConsignmentUtils} from "../../../consignment.utils";
import {cloneDeep} from "lodash";
import {checkValidity, clearValidation, sendCommand, uuid} from "../../../../../common/utils";
import {AppContext} from "../../../../../app-context";
import {Observable} from "rxjs";

@Component({
  selector: 'app-consignment-party-details',
  templateUrl: './consignment-party-details.component.html',
  styleUrls: ['./consignment-party-details.component.scss']
})
export class ConsignmentPartyDetailsComponent implements OnInit {
  portVisitUtils = PortvisitUtils;
  utils = ConsignmentUtils;
  elementRef: ElementRef = inject(ElementRef);

  @Input() cargoDeclarantId: string;
  @Input() label: string;
  @Input() party: IE3Party;
  @Input() editMode: boolean;
  @Input() required: boolean;

  addressBookId: string;
  partyEditMode: boolean;
  backupParty: IE3Party;
  addressBook: IE3AddressBook;

  @Output() partyChange: EventEmitter<IE3Party> = new EventEmitter<IE3Party>();

  countryFormatter = (value: Country | string) => typeof value === "string" ? value : value.code;

  ngOnInit() {
    if (this.party) {
      ConsignmentUtils.findParties(null, this.cargoDeclarantId, this.party)
        .subscribe((p: IE3AddressBook[]) => this.updateAddressBook(p[0]));
    }
  }

  findParties = (term: string): Observable<IE3AddressBook[]> => ConsignmentUtils.findParties(term, this.cargoDeclarantId);

  get telephone() {
    return this.party.communications.find(c => c.type === IE3CL707.TE) || {};
  }

  get email() {
    return this.party.communications.find(c => c.type === IE3CL707.EM) || {};
  }

  toggleEditMode = () => {
    this.partyEditMode = !this.partyEditMode;
    if (this.partyEditMode) {
      this.backupParty = cloneDeep(this.party);
    }
  }

  cancel = () => {
    this.party = this.backupParty;
    this.toggleEditMode();
    clearValidation(this.elementRef);
  }

  save = () => {
    if (checkValidity(this.elementRef)) {
      this.toggleEditMode();
      this.partyChange.emit(this.party);
      if (this.addressBook["saveInAddressBook"]) {
        this.addressBookId = this.addressBookId || uuid();
        sendCommand("com.portbase.bezoekschip.common.api.consignments.addressbook.UpsertPartyInAddressBook", <IE3UpsertPartyInAddressBook> {
          addressBookId: this.addressBookId,
          party: this.party
        }, () => AppContext.registerSuccess("Successfully saved party in address book"));
      }
    }
  }

  newParty = (value: string): IE3AddressBook => {
    const addressBook = this.createAddressBookFromParty({
      name: value,
      address: {},
      communications: []
    });
    addressBook["isNewRecord"] = true;
    return addressBook;
  }

  onPartyChange = (value: IE3AddressBook) => {
    this.addressBook = value;
    this.party = value?.party;
    if (value && value["isNewRecord"]) {
      this.partyEditMode = true;
    }
    if (!this.partyEditMode) {
      this.partyChange.emit(this.party);
    }
  }

  private updateAddressBook(addressBook: IE3AddressBook) {
    this.addressBook = addressBook || this.createAddressBookFromParty(this.party);
    this.addressBookId = this.addressBook.addressBookId;
    if (addressBook) {
      this.addressBook["saveInAddressBook"] = true;
    }
  }

  private createAddressBookFromParty = (party: IE3Party): IE3AddressBook => ({
    party: party,
    addressBookId: uuid(),
    ownerIamConnectedId: this.cargoDeclarantId
  });
}
