import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
  ConsignmentSubModalEvent,
  ConsignmentUtils,
  EquipmentWithPlacement,
  GenericGoodsItem
} from "../../consignment.utils";
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3GetConsignment,
  IE3GoodsItem,
  IE3Packaging,
  IE3SaveConsignment,
  IE3SupportingDocument
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openEditModal,
  publishEvent,
  removeItem,
  sendCommand,
  sendQuery,
  showPreviousInStack
} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../house-consignment-details/house-consignment-details.component";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {EventGateway} from "../../../../common/event-gateway";

@Component({
  selector: 'app-goods-consignment-details',
  templateUrl: './goods-consignment-details.component.html',
  styleUrls: ['./goods-consignment-details.component.scss']
})
export class GoodsConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  refData = PortvisitUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  _goodsItem: GenericGoodsItem;
  goodsItemIndex: number;
  data: GoodsConsignmentDetailsComponentData;
  isNewGoodsItem: boolean;
  editMode: boolean;
  equipmentListSummaries: EquipmentWithPlacement[];

  @ViewChild("subModalContainer", {read: ViewContainerRef}) subModalContainer: ViewContainerRef;
  showSubModal: boolean = false;
  private readonly registration: () => void;

  constructor(private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit(): void {
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {caching: false}).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c));
    }
  }

  ngOnDestroy() {
    this.registration();
  }

  set goodsItem(goodsItem: GenericGoodsItem) {
    if (goodsItem != null) {
      this._goodsItem = goodsItem
    } else {
      this._goodsItem = this.createGoodsItem();
      if (this.isEditable) {
        this.isNewGoodsItem = true;
        this.editMode = true;
      }
    }
    this.updateEquipmentSummaries();
    if (this.houseConsignment) {
      this.houseConsignment.transportEquipmentNumbers = [...new Set(this.houseConsignment.transportEquipmentNumbers
        .concat((goodsItem?.goodsPlacements || []).map(g => g.containerIdentificationNumber)))];
    }
  }

  get goodsItem(): GenericGoodsItem {
    return this._goodsItem;
  }

  goodsItemsOfConsignment = (): GenericGoodsItem[] => {
    return this.houseConsignment
      ? this.houseConsignment.goodsItems.map(item => ConsignmentUtils.toGenericGoodsItemFromHouse(item))
      : this.consignmentProcess.consignmentMasterLevel.goodsItems.map(item => ConsignmentUtils.toGenericGoodsItemFromMaster(item));
  }

  mapGoodsItem = (): IE3GoodsItem => {
    return this.houseConsignment ? ConsignmentUtils.toHouseGoodsItemFromGeneric(this.goodsItem)
      : ConsignmentUtils.toMasterGoodsItemFromGeneric(this.goodsItem);
  }

  remove = () => {

  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  save = () => {
    const goodsItems = this.houseConsignment ? this.houseConsignment.goodsItems
      : this.consignmentProcess.consignmentMasterLevel.goodsItems;
    if (!goodsItems[this.goodsItemIndex]) {
      this.goodsItemIndex = goodsItems.push(this.mapGoodsItem()) - 1;
    } else {
      goodsItems[this.goodsItemIndex] = this.mapGoodsItem();
    }
    if (this.isValid) {
      this.processModel();
      if (ConsignmentUtils.hasBeenDeclared(this.consignmentProcess)) {
        if (this.houseConsignment) {
          this.openHouseConsignment();
        } else {
          this.openMasterConsignment();
        }
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.consignmentProcess.consignmentMasterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          showPreviousInStack({
            amount: 1,
            endReached: this.houseConsignment ? this.openHouseConsignment : this.openMasterConsignment
          });
        });
      }
    }
  }

  get isValid(): boolean {
    return checkValidity(this.elementRef);
  }

  processModel = (): void => {
    if (this.goodsItem.outerPackaging?.typeOfPackages?.bulk) {
      this.goodsItem.outerPackaging.numberOfPackages = null;
      this.goodsItem.outerPackaging.shippingMarks = null;
      this.goodsItem.innerPackagingList = null;
    }
  }

  openMasterConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    showPreviousInStack({
      amount: 2,
      endReached: () =>
        openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
        }, {
          warnOnClose: true,
          currentToStack: true
        })
    });
  }

  openHouseConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    showPreviousInStack({
      amount: 1,
      endReached: () =>
        openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignmentNumber: this.houseConsignment.consignmentNumber
        }, {
          warnOnClose: true,
          currentToStack: true
        })
    });
  }

  private createGoodsItem = (): GenericGoodsItem => ({
    goodsItemNumber: this.goodsItemsOfConsignment().length + 1,
    innerPackagingList: [],
    goodsPlacements: this.data?.equipmentNumber ? [{
      containerIdentificationNumber: this.data.equipmentNumber
    }] : [],
    commodity: {},
    outerPackaging: {},
    previousDocument: {}
  })

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  addInnerPackage = () => this.goodsItem.innerPackagingList.push({
    shippingMarks: []
  });

  deleteInnerPackage = (item: IE3Packaging) => removeItem(this.goodsItem.innerPackagingList, item);

  addSupportingDocument = () => this.goodsItem.supportingDocuments.push({});

  deleteSupportingDocument = (item: IE3SupportingDocument) => removeItem(this.goodsItem.supportingDocuments, item);

  private setConsignment = (consignmentProcess: IE3ConsignmentProcess) => {
    this.consignmentProcess = consignmentProcess;
    this.houseConsignment = consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
      .find(h => h.consignmentNumber === this.data.houseConsignmentNumber);
    const goodsItems = this.goodsItemsOfConsignment();
    this.goodsItem = goodsItems.find(g => g.goodsItemNumber === this.data.goodsItemNumber);
    this.goodsItemIndex = this.goodsItem ? goodsItems.indexOf(this.goodsItem) : goodsItems.length;
    this.goodsItem.previousDocument = this.goodsItem.previousDocument || {};
    this.goodsItem.supportingDocuments = this.goodsItem.supportingDocuments || [];
    this.updateEquipmentSummaries();
  }

  private updateEquipmentSummaries() {
    this.equipmentListSummaries = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
      .filter(([containerIdentificationNumber, equipment]) =>
        this.goodsItem.goodsPlacements && this.goodsItem.goodsPlacements.some(g => g.containerIdentificationNumber === containerIdentificationNumber))
      .map(([containerIdentificationNumber, equipment]) =>
        ConsignmentUtils.getContainerWithPlacement(equipment, this.goodsItem));
  }

  "openConsignmentSubModal" = (event: ConsignmentSubModalEvent) => {
    this.subModalContainer.clear();
    if (event.modalContent) {
      this.subModalContainer.createEmbeddedView(event.modalContent);
    }
    this.showSubModal = true;
  }

  "closeConsignmentSubModal" = () => {
    this.showSubModal = false;
    this.editMode = true;
  }
}

export interface GoodsConsignmentDetailsComponentData {
  consignmentProcessId: string;
  houseConsignmentNumber: string;
  goodsItemNumber: number;
  equipmentNumber: string;
  cachedConsignmentProcess: IE3ConsignmentProcess;
}
