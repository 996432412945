import {Component, ElementRef, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  BezoekschipOrganisation,
  Country,
  CustomsOffice,
  FindVisits,
  FindVisitsResult,
  GetOrganisationByShortName,
  GetVisit,
  IE3AdditionalSupplyChainActor,
  IE3CL704,
  IE3ConsignmentProcess,
  IE3DeclareConsignment,
  IE3FilingType,
  IE3FindCustomsOfficesFirstEuCountry,
  IE3GetConsignment,
  IE3HouseConsignment,
  IE3InvalidateConsignment,
  IE3Place,
  IE3SaveConsignment,
  IE3SupportingDocument,
  IE3TransportEquipment,
  Port,
  Terminal,
  Vessel,
  Visit,
  VisitSummary,
  VisitSummarySortingProperty
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  clearValidation,
  cloneObject,
  closeEditModal,
  formDataSaved,
  openConfirmationModalWithCallback,
  removeItem,
  sendCommand,
  sendQuery
} from "../../../../common/utils";
import {ConsignmentRouteInfo} from "./master-consignment-route/master-consignment-route.component";
import {AppContext} from "../../../../app-context";
import {ConsignmentUtils, EquipmentWithPlacement, GoodsItemWithHouseConsignments} from "../../consignment.utils";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {of} from "rxjs";
import {WebsocketService} from "../../../../common/websocket.service";
import lodash, {cloneDeep} from "lodash";
import {EventGateway} from "../../../../common/event-gateway";
import {map} from "rxjs/operators";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../../common/modal/modal-confirm.component";

@Component({
  selector: 'app-master-consignment-details',
  templateUrl: './master-consignment-details.component.html',
  styleUrls: ['./master-consignment-details.component.scss'],
  providers: [WebsocketService]
})
export class MasterConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  refData = PortvisitUtils;
  consignmentProcess: IE3ConsignmentProcess;
  data: MasterConsignmentDetailsComponentData;
  countries: Country[];
  isNewConsignment: boolean;
  editMode: boolean;
  customsOffices: CustomsOffice[] = [];
  _routes: ConsignmentRouteInfo[] = [];
  mrn: string;
  billOfLadingFilingType: BillOfLadingFilingType = {};
  billOfLadingFilingTypeMapper: { [index: string]: BillOfLadingFilingType } = {
    [IE3FilingType.F10]: {billOfLadingType: BillOfLadingType.Straight, filing: Filing.Single},
    [IE3FilingType.F11]: {billOfLadingType: BillOfLadingType.Master, filing: Filing.Single},
    [IE3FilingType.F12]: {billOfLadingType: BillOfLadingType.Master, filing: Filing.Multiple},
    [IE3FilingType.F13]: {billOfLadingType: BillOfLadingType.Straight, filing: Filing.Multiple}
  };
  billOfLadingTypes: BillOfLadingType[] = Object.values(BillOfLadingType);
  filingTypes: Filing[] = Object.values(Filing);

  houseConsignments: IE3HouseConsignment[];
  equipments: EquipmentWithPlacement[];
  goodsItems: GoodsItemWithHouseConsignments[] = [];

  findVisits = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindVisits", <FindVisits>{
    maxHits: 100,
    term: term,
    sortingProperty: VisitSummarySortingProperty.CREATED
  }).pipe(map((result: FindVisitsResult) => result.visits));

  visitFormatter = (visit: VisitSummary | string) => visit ? lodash.isString(visit) ? visit : visit.crn : null;

  private readonly registration: () => void;

  constructor(private websocket: WebsocketService<IE3ConsignmentProcess>, private ngZone: NgZone, private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  bulkAuthorisationFormatter = (value: BezoekschipOrganisation[]) => value.map(PortvisitUtils.bulkAuthorisationFormatter).join(", ");
  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});
  vesselFormatter = (vessel: Vessel) => vessel.name + ' – ' + vessel.imoCode;

  findTerminals = (term: string) => this.refData.findTerminals(term, this.consignmentProcess.consignmentMasterLevel.placeOfUnloading.locationUnCode);

  refreshCustomsOfficeProvider = () =>
    (this.consignmentProcess?.consignmentMasterLevel.activeBorderTransportMeans?.itinerary ?
      sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindCustomsOfficesFirstEuCountry', <IE3FindCustomsOfficesFirstEuCountry>{
        countryUnCodes: this.consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.itinerary
      }) : of([])).subscribe((c: CustomsOffice[]) => this.customsOffices = c);

  ngOnInit(): void {
    this.isNewConsignment = !this.data.consignmentProcessId && !this.data.cachedConsignmentProcess;
    if (this.isNewConsignment) {
      this.editMode = true;
      this.consignmentProcess = this.createNewConsignment(this.data.transportEquipmentMap, this.data.crn);
      if (this.data.crn) {
        this.updateDataFromVisit(this.data.crn);
      }
      this.updateFromConsignment(this.consignmentProcess);
    } else {
      this.websocket.initialise(`/api/ui/${this.data.consignmentProcessId}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
      if (!this.data.cachedConsignmentProcess) {
        sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
          consignmentProcessId: this.data.consignmentProcessId
        }, {
          caching: false
        }).subscribe(this.updateFromConsignment);
      } else {
        this.updateFromConsignment(this.data.cachedConsignmentProcess);
      }
    }
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
      .subscribe((c: Country[]) => this.countries = c);
  }

  ngOnDestroy() {
    this.registration();
  }

  'cachedConsignmentUpdated' = (c: IE3ConsignmentProcess) => {
    if (c?.consignmentProcessId === this.consignmentProcess.consignmentProcessId) {
      this.updateFromConsignment(c);
    }
  }

  onPlaceOfUnloadingChange = (placeOfUnloading: IE3Place) => {
    this.consignmentProcess.consignmentMasterLevel.dischargeTerminal = null;
    this.consignmentProcess.consignmentMasterLevel.terminalLicense = null;
    this.checkWarehouseKeeper(placeOfUnloading);
  }

  onTerminalChange = (terminal: Terminal) => {
    this.consignmentProcess.consignmentMasterLevel.terminalLicense = null;
    if (terminal != null) {
      sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetOrganisationByShortName", <GetOrganisationByShortName>{
        shortName: terminal.organisationShortName
      }, {
        caching: true
      }).subscribe(t => this.consignmentProcess.consignmentMasterLevel.terminalLicense = t.rtoNumber);
    }
  }

  private updateFromConsignment = (c: IE3ConsignmentProcess) => {
    this.consignmentProcess = c;
    this.consignmentProcess.status = this.consignmentProcess.status || {};
    this.consignmentProcess.consignmentMasterLevel.previousDocument = this.consignmentProcess.consignmentMasterLevel.previousDocument || {};
    this.consignmentProcess.consignmentMasterLevel.transportCharges = this.consignmentProcess.consignmentMasterLevel.transportCharges || {};
    this.consignmentProcess.consignmentMasterLevel.exchangeRate = this.consignmentProcess.consignmentMasterLevel.exchangeRate || {};
    this.consignmentProcess.consignmentMasterLevel.bulkAuthorisations = this.consignmentProcess.consignmentMasterLevel.bulkAuthorisations || [];
    this.mrn = this.consignmentProcess.status.filingStatusENS?.ensRegistrationResponse?.mrn;
    this.billOfLadingFilingType = cloneObject(this.billOfLadingFilingTypeMapper[c.filing.filingType]) || {};
    this.refreshCustomsOfficeProvider();
    this._routes = this.getRoutesOfConsignment();
    this.checkWarehouseKeeper(this.consignmentProcess.consignmentMasterLevel.placeOfUnloading);
    this.consignmentProcess.consignmentMasterLevel.transportCharges = this.consignmentProcess.consignmentMasterLevel.transportCharges || {};;
    this.equipments = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
      .map(([containerIdentificationNumber, equipment]) => (<EquipmentWithPlacement>{
        equipment: equipment,
        placement: null,
        goodsItems: ConsignmentUtils.getGoodsOfEquipment(this.consignmentProcess, containerIdentificationNumber),
        houseConsignments: this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
          .filter(h => h.transportEquipmentNumbers.includes(containerIdentificationNumber))
          .map(h => h.consignmentNumber)
      }));
    this.houseConsignments = this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
      .map(c => {
        const equipmentList = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
          .filter(([containerIdentificationNumber, equipment]) =>
            c.transportEquipmentNumbers.includes(containerIdentificationNumber))
          .map(([containerIdentificationNumber, equipment]) => equipment)
        return {
          consignmentNumber: c.consignmentNumber,
          consignee: c.consignee,
          consignor: c.consignor,
          goodsItems: c.goodsItems.map(g => ({
            goodsItemNumber: g.goodsItemNumber,
            placements: g.goodsPlacements,
            grossWeight: g.grossWeight,
            commodity: g.commodity
          })),
          equipments: equipmentList.map(t => ({
            equipmentNumber: t.containerIdentificationNumber,
            empty: t.empty,
            sizeType: t.containerSizeAndType
          }))
        }
      });
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(this.consignmentProcess.consignmentMasterLevel.goodsItems)
      .map(g => (<GoodsItemWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
          .filter(h => h.goodsItems.some(
            hg => hg.commodity?.commodityCode?.harmonizedSystemSubHeadingCode === g.commodity?.commodityCode?.harmonizedSystemSubHeadingCode))
          .map(h => h.consignmentNumber)
      }));
  }

  getRoutesOfConsignment = (): ConsignmentRouteInfo[] => {
    if (!this.consignmentProcess || !this.countries) {
      return [];
    }
    const activeBorderTransportMeans = this.consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans;
    const placeOfDischarge = this.consignmentProcess.consignmentMasterLevel.placeOfUnloading;
    const placeOfLoading = this.consignmentProcess.consignmentMasterLevel.placeOfLoading;
    const placeOfAcceptance = this.consignmentProcess.consignmentMasterLevel.placeOfAcceptance;
    let placeOfLoadingFound, placeOfDischargeFound, placeOfAcceptanceFound = false;
    return activeBorderTransportMeans.itinerary.map((c, i) => {
      const isPlaceOfLoading = !placeOfLoadingFound && placeOfLoading?.countryUnCode === c;
      if (isPlaceOfLoading) {
        placeOfLoadingFound = true;
      }
      const isPlaceOfDischarge = !placeOfDischargeFound && placeOfDischarge?.countryUnCode === c;
      if (isPlaceOfDischarge) {
        placeOfDischargeFound = true;
      }
      const isPlaceOfAcceptance = !placeOfAcceptanceFound && placeOfAcceptance?.countryUnCode === c;
      if (isPlaceOfAcceptance) {
        placeOfAcceptanceFound = true;
      }
      return (<ConsignmentRouteInfo>{
        country: this.countries.find(country => country.code === c),
        placeOfDischarge: isPlaceOfDischarge ? placeOfDischarge : null,
        placeOfLoading: isPlaceOfLoading ? placeOfLoading : null,
        placeOfAcceptance: isPlaceOfAcceptance ? placeOfAcceptance : null,
        etd: isPlaceOfLoading ? activeBorderTransportMeans.actualDateAndTimeOfDeparture
          || activeBorderTransportMeans.estimatedDateAndTimeOfDeparture : null,
        eta: isPlaceOfDischarge ? activeBorderTransportMeans.estimatedDateAndTimeOfArrival : null,
        dischargeTerminal: isPlaceOfDischarge ? this.consignmentProcess.consignmentMasterLevel.dischargeTerminal : null,
        terminalLicense: isPlaceOfDischarge ? this.consignmentProcess.consignmentMasterLevel.terminalLicense : null
      });
    });
  }

  get equipmentSupported(): boolean {
    return !this.consignmentProcess.filing.filingType || [IE3FilingType.F11, IE3FilingType.F12, IE3FilingType.TSD,
      IE3FilingType.TSR].includes(this.consignmentProcess.filing.filingType);
  }

  get routes(): ConsignmentRouteInfo[] {
    return this._routes;
  }

  set routes(routes: ConsignmentRouteInfo[]) {
    this.consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.itinerary = routes.map(r => r.country.code);
    this.refreshCustomsOfficeProvider();
    this._routes = this.getRoutesOfConsignment();
  }

  get previousDocumentRequired(): boolean {
    return ConsignmentUtils.isDutchPort(this.consignmentProcess.consignmentMasterLevel.placeOfUnloading)
      && !this.consignmentProcess.filing.declarant?.ensEnabled;
  }

  addRoute = () => {
    this._routes.push({
      country: {}
    });
  }

  remove = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.InvalidateConsignment", <IE3InvalidateConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignmentNumber: this.consignmentProcess.consignmentMasterLevel.consignmentNumber,
          cargoDeclarantId: this.consignmentProcess.filing.declarant.iamConnectedId
        });
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Remove consignment",
      message: "You are about to remove consignment: " + this.consignmentProcess.consignmentMasterLevel.consignmentNumber,
      question: "Are you sure that you want to remove this consignment?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  cancel = () => {
    clearValidation(this.elementRef);
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  toggleEdit = () => {
    if (checkValidity(this.elementRef)) {
      this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
    }
  }

  save = () => {
    if (this.isNewConsignment) {
      this.websocket.initialise(`/api/ui/${this.consignmentProcess.consignmentProcessId}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
    }
    const consignment = cloneDeep(this.consignmentProcess.consignmentMasterLevel);
    sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
      consignment: consignment,
      filing: this.consignmentProcess.filing
    }, () => {
      AppContext.registerSuccess("Consignment saved successfully");
      formDataSaved();
      this.editMode = false;
    })
  }

  declare = () => {
    if (this.isNewConsignment) {
      this.websocket.initialise(`/api/ui/${this.consignmentProcess.consignmentProcessId}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
    }
    if (ConsignmentUtils.isStraightConsignment(this.consignmentProcess)) {
      this.consignmentProcess.consignmentMasterLevel.placeOfDelivery = null;
      this.consignmentProcess.consignmentMasterLevel.placeOfAcceptance = null;
    }
    if (checkValidity(this.elementRef)) {
      const consignment = cloneDeep(this.consignmentProcess.consignmentMasterLevel);
      if (!consignment.exchangeRate?.currency) {
        consignment.exchangeRate = null;
      }
      if (!consignment.transportCharges?.methodOfPayment) {
        consignment.transportCharges = null;
      }
      sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.DeclareConsignment", <IE3DeclareConsignment>{
        consignment: consignment,
        filing: this.consignmentProcess.filing
      }, () => {
        AppContext.registerSuccess("Consignment declared successfully");
        formDataSaved();
        closeEditModal();
      });
    }
  }

  createNewConsignment = (transportEquipment?: { [containerIdentificationNumber: string]: IE3TransportEquipment },
                          crn?: string): IE3ConsignmentProcess => {
    return ({
      consignmentMasterLevel: {
        activeBorderTransportMeans: {},
        previousDocument: {},
        transportEquipmentMap: transportEquipment || {},
        goodsItems: [],
        consignmentsHouseLevel: [],
      },
      filing: {
        declarant: AppContext.userProfile.organisation,
        filingType: null,
        crn: crn
      }
    })
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  get isAllowedToDeclare() {
    return ConsignmentUtils.isAllowedToDeclare(this.consignmentProcess);
  }

  get isDutchPort() {
    return ConsignmentUtils.isDutchPort(this.consignmentProcess?.consignmentMasterLevel?.placeOfUnloading);
  }

  setBulkAuthorisations = (authorisations: BezoekschipOrganisation[]) =>
    this.consignmentProcess.consignmentMasterLevel.bulkAuthorisations = authorisations || [];

  addSupportingDocument = () => this.consignmentProcess.consignmentMasterLevel.supportingDocuments.push({});

  deleteSupportingDocument = (item: IE3SupportingDocument) => removeItem(this.consignmentProcess.consignmentMasterLevel.supportingDocuments, item);

  setConsignmentProcessId = (consignmentNumber: string) => {
    this.consignmentProcess.consignmentProcessId = `${AppContext.userProfile.organisation.iamConnectedId}_${consignmentNumber}`;
  }

  updateFilingType = (): void => {
    this.ngZone.runOutsideAngular(() => {
      const foundEntry = Object.entries(this.billOfLadingFilingTypeMapper)
        .find(e => lodash.isEqual(e[1], this.billOfLadingFilingType));
      this.consignmentProcess.filing.filingType = foundEntry ? foundEntry[0] as IE3FilingType : null;
    });
  }

  addSupplyChainActor = (): void => {
    const actors = this.consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors || [];
    actors.push({["isNewRecord"]: true} as any);
    this.consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors = actors;
  }

  checkSupplyChainActorDeletion = (actor: IE3AdditionalSupplyChainActor, index: number) => {
    if (!actor) {
      this.consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors.splice(index, 1);
    }
  }

  showDetails = (): boolean => !this.isNewConsignment || !!this.consignmentProcess?.filing?.filingType;

  onCrnChange = (value: VisitSummary) => {
    if (!value) {
      this.updateVisitFields(null);
    }
    this.updateDataFromVisit(value.crn);
  }

  private updateDataFromVisit = (crn: string) =>
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", <GetVisit>{crn: crn})
      .subscribe((v: Visit) => this.updateVisitFields(v));

  private updateVisitFields(v?: Visit) {
    const consignmentMasterLevel = this.consignmentProcess.consignmentMasterLevel;
    const activeBorderTransportMeans = consignmentMasterLevel.activeBorderTransportMeans;
    this.consignmentProcess.filing.crn = v?.crn;
    activeBorderTransportMeans.vessel = v?.vessel;
    consignmentMasterLevel.carrier = v?.visitDeclaration.arrivalVoyage.carrier;
    consignmentMasterLevel.agentVoyageNumber = v?.visitDeclaration.arrivalVoyage.voyageNumber;
    activeBorderTransportMeans.estimatedDateAndTimeOfArrival = activeBorderTransportMeans.estimatedDateAndTimeOfArrival
      || v?.visitDeclaration.portVisit.etaPort;
    consignmentMasterLevel.placeOfUnloading = this.convertPortToPlace(v?.portOfCall.port);
    activeBorderTransportMeans.itinerary = lodash.uniq(
      (v?.visitDeclaration.previousPorts.map(p => p.port.countryUnCode).reverse() || []).concat(
        consignmentMasterLevel.placeOfUnloading ? [consignmentMasterLevel.placeOfUnloading.countryUnCode] : []));
    this._routes = this.getRoutesOfConsignment();
    this.refreshCustomsOfficeProvider();
  }

  get transportEquipmentCount(): number {
    return Object.keys(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap).length;
  }

  private checkWarehouseKeeper(placeOfUnloading: IE3Place) {
    if (ConsignmentUtils.isDutchPort(placeOfUnloading)) {
      if (!ConsignmentUtils.isStraightConsignment(this.consignmentProcess)) {
        const alreadyHasWarehouseKeeper = this.consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors
          .some(a => a.type === IE3CL704.WH);
        if (!alreadyHasWarehouseKeeper) {
          this.consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors.push({
            type: IE3CL704.WH
          });
        }
      }
    }
  }

  private convertPortToPlace(port: Port | undefined): IE3Place {
    return port ? {
      name: port.name,
      countryUnCode: port.countryUnCode,
      euLocation: port.euPort,
      locationUnCode: port.locationUnCode
    } : null;
  }
}

export interface MasterConsignmentDetailsComponentData {
  consignmentProcessId: string;
  crn: string,
  transportEquipmentMap: { [containerIdentificationNumber: string]: IE3TransportEquipment };
  cachedConsignmentProcess: IE3ConsignmentProcess;
}

interface BillOfLadingFilingType {
  billOfLadingType?: BillOfLadingType;
  filing?: Filing;
}

enum BillOfLadingType {
  Straight = "Straight",
  Master = "Master"
}

enum Filing {
  Single = "Single",
  Multiple = "Multiple"
}
