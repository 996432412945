import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
  Country,
  IE3AdditionalSupplyChainActor,
  IE3CL704,
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3GetConsignment,
  IE3SaveConsignment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openEditModal,
  publishEvent,
  sendCommand,
  sendQuery,
  showPreviousInStack
} from "../../../../common/utils";
import {
  ConsignmentSubModalEvent,
  ConsignmentUtils,
  EquipmentWithPlacement,
  GoodsItemWithHouseConsignments
} from "../../consignment.utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {
  ConsignmentRouteInfo
} from "../master-consignment-details/master-consignment-route/master-consignment-route.component";
import {EventGateway} from "../../../../common/event-gateway";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-house-consignment-details',
  templateUrl: './house-consignment-details.component.html',
  styleUrls: ['./house-consignment-details.component.scss']
})
export class HouseConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  refData = PortvisitUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  data: HouseConsignmentDetailsComponentData;
  recordIndex: number;
  isNewConsignment: boolean;
  editMode: boolean;
  countries: Country[];
  _routes: ConsignmentRouteInfo[] = [];

  equipmentListSummaries: EquipmentWithPlacement[];
  goodsItems: GoodsItemWithHouseConsignments[];

  @ViewChild("subModalContainer", {read: ViewContainerRef}) subModalContainer: ViewContainerRef;
  showSubModal: boolean = false;

  private readonly registration: () => void;

  constructor(private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit(): void {
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {
        caching: false
      }).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c));
    }
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
      .subscribe((c: Country[]) => this.countries = c);
  }

  ngOnDestroy() {
    this.registration();
  }

  'cachedConsignmentUpdated' = (c: IE3ConsignmentProcess) => {
    if (c?.consignmentProcessId === this.consignmentProcess.consignmentProcessId) {
      this.setConsignment(c);
    }
  }

  get routes(): ConsignmentRouteInfo[] {
    return this._routes;
  }

  set routes(routes: ConsignmentRouteInfo[]) {
    this.houseConsignment.itinerary = routes.map(r => r.country.code);
    this._routes = this.getRoutesOfConsignment();
  }

  addRoute = () => {
    this._routes.push({
      country: {}
    });
  }

  remove = () => {

  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  save = () => {
    const records = this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel;
    if (!records[this.recordIndex]) {
      this.recordIndex = records.push(this.houseConsignment) - 1;
    } else {
      records[this.recordIndex] = this.houseConsignment;
    }
    if (this.isValid) {
      this.processModel();
      if (ConsignmentUtils.hasBeenDeclared(this.consignmentProcess)) {
        this.openMasterConsignment();
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.consignmentProcess.consignmentMasterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          this.openMasterConsignment();
        }, (error) => {
          AppContext.registerError(error);
          this.houseConsignment.goodsShipment = this.houseConsignment.goodsShipment || {};
        });
      }
    }
  }

  openMasterConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    showPreviousInStack({
      endReached: () =>
        openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
          cachedConsignmentProcess: this.consignmentProcess
        }, {
          warnOnClose: true,
          currentToStack: true
        })
    });
  }

  addSupplyChainActor = (): void => {
    const actors = this.houseConsignment.additionalSupplyChainActors || [];
    actors.push({["isNewRecord"]: true} as any);
    this.houseConsignment.additionalSupplyChainActors = actors;
  }

  checkSupplyChainActorDeletion = (actor: IE3AdditionalSupplyChainActor, index: number) => {
    if (!actor) {
      this.houseConsignment.additionalSupplyChainActors.splice(index, 1);
    }
  }

  processModel = (): void => {
    if (!this.houseConsignment.goodsShipment?.buyer && !this.houseConsignment.goodsShipment?.seller) {
      this.houseConsignment.goodsShipment = null;
    }
    if (!this.houseConsignment.transportCharges?.methodOfPayment) {
      this.houseConsignment.transportCharges = null;
    }
  }

  get isValid(): boolean {
    if (!checkValidity(this.elementRef)) {
      return false;
    }
    const duplicateHouseConsignments = this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
      .filter(h => h.consignmentNumber === this.houseConsignment.consignmentNumber).length > 1;
    if (duplicateHouseConsignments) {
      AppContext.registerError(`Multiple house consignments with consignment number ${this.houseConsignment.consignmentNumber} found`);
      return false;
    }
    return true;
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  private setConsignment = (c: IE3ConsignmentProcess) => {
    this.consignmentProcess = c;
    this.houseConsignment = c.consignmentMasterLevel.consignmentsHouseLevel
      .find(c => c.consignmentNumber === this.data.consignmentNumber);
    if (this.houseConsignment) {
      this.recordIndex = c.consignmentMasterLevel.consignmentsHouseLevel.indexOf(this.houseConsignment);
      if (!this.houseConsignment.goodsShipment) {
        this.houseConsignment.goodsShipment = {};
      }
    } else {
      this.isNewConsignment = true;
      this.editMode = true;
      this.houseConsignment = this.createHouseConsignment();
    }
    this.houseConsignment.transportCharges = this.houseConsignment.transportCharges || {};
    if (ConsignmentUtils.isDutchPort(c.consignmentMasterLevel.placeOfUnloading)) {
      if (ConsignmentUtils.isStraightConsignment(this.consignmentProcess)) {
        const alreadyHasWarehouseKeeper = this.houseConsignment.additionalSupplyChainActors
          .some(a => a.type === IE3CL704.WH);
        if (!alreadyHasWarehouseKeeper) {
          this.houseConsignment.additionalSupplyChainActors.push({
            type: IE3CL704.WH
          });
          this.editMode = ConsignmentUtils.allowedToEdit();
        }
      }
    }
    this._routes = this.getRoutesOfConsignment();
    this.equipmentListSummaries = Object.entries(this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
      .filter(([containerIdentificationNumber, equipment]) =>
        this.houseConsignment.transportEquipmentNumbers.includes(containerIdentificationNumber))
      .map(([containerIdentificationNumber, equipment]) => (<EquipmentWithPlacement>{
        equipment: equipment,
        placement: null,
        goodsItems: ConsignmentUtils.getGoodsOfEquipment(
          this.consignmentProcess, containerIdentificationNumber, this.houseConsignment.consignmentNumber),
        houseConsignments: this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
          .filter(h => h.transportEquipmentNumbers.includes(containerIdentificationNumber))
          .map(h => h.consignmentNumber)
      }));
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(this.houseConsignment.goodsItems)
      .map(g => (<GoodsItemWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: []
      }));
  }

  private createHouseConsignment = (): IE3ConsignmentHouseLevel => {
    return {
      transportCharges: {},
      goodsShipment: {},
      goodsItems: [],
      transportEquipmentNumbers: [],
      itinerary: []
    }
  }

  getRoutesOfConsignment = (): ConsignmentRouteInfo[] => {
    if (!this.houseConsignment || !this.countries) {
      return [];
    }
    const placeOfAcceptance = this.houseConsignment.placeOfAcceptance;
    let placeOfAcceptanceFound = false;
    return this.houseConsignment.itinerary.map((c, i) => {
      const isPlaceOfAcceptance = !placeOfAcceptanceFound && placeOfAcceptance?.countryUnCode === c;
      if (isPlaceOfAcceptance) {
        placeOfAcceptanceFound = true;
      }
      return (<ConsignmentRouteInfo>{
        country: this.countries.find(country => country.code === c),
        placeOfAcceptance: isPlaceOfAcceptance ? placeOfAcceptance : null,
      });
    });
  }

  "openConsignmentSubModal" = (event: ConsignmentSubModalEvent) => {
    this.subModalContainer.clear();
    if (event.modalContent) {
      this.subModalContainer.createEmbeddedView(event.modalContent);
    }
    this.showSubModal = true;
  }

  "closeConsignmentSubModal" = () => {
    this.showSubModal = false;
    this.setConsignment(this.consignmentProcess);
  }
}

export interface HouseConsignmentDetailsComponentData {
  consignmentProcessId: string;
  consignmentNumber: string;
  cachedConsignmentProcess: IE3ConsignmentProcess;
}
