import {Component, Input, OnInit, TemplateRef, Type} from '@angular/core';
import {
  AcceptTerminalPlanningForBerth,
  BaseForPlanning,
  Berth,
  BerthVisit,
  Bollards,
  GetStevedores,
  GetTerminalsOfOrganisation,
  GetVesselServices,
  LocalMovement,
  Mooring,
  PurposeCode,
  Stevedore,
  Terminal,
  TerminalMooring,
  TerminalVisit,
  VesselService
} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from 'moment'
import {VisitContext} from "../../../visit-context";
import {sendCommand, sendQuery} from "../../../../common/utils";
import {AppContext} from "../../../../app-context";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

export class DateTimeEntry {
  id: string;
  label: string;
  subLabel?: string;
  classes?: string = "";
  disabled?: boolean = false;
  required: () => boolean = () => false;
  info?: string;
  time: string;
  onChanged?: (date: string) => void;
  dateTimeTemplate?: TemplateRef<any>;
  dateTimeType?: Type<any>;
  columns?: string = "";
  init?;
}

const TerminalsThatProvidePlanning = ["RWG", "APMII", "APMRTM", "ECTDELTA", "EUROMAX"];

@Component({
	selector: 'app-berth-visit-details',
	templateUrl: './berth-visit-details.component.html',
	styleUrls: ['./berth-visit-details.component.css']
})
export class BerthVisitDetailsComponent implements OnInit {
  utils = PortvisitUtils;
	context = VisitContext;
  appContext = AppContext;

  @Input() times: DateTimeEntry[] = [];

	@Input() berthVisit: BerthVisit;
	@Input() isBerthOfTransfer: boolean;
	@Input() firstBerth: boolean;
	@Input() isNextBerthVisit: boolean;
	@Input() pilotOnBoard: string;
	@Input() lastBerth: boolean;

	suggestedBerth: string;
	outsideRange: boolean;
  planningBasedForcedWarning: boolean;
  bollardsNotInOrder: boolean;
  bollardRange: Bollards = {start: 0, end: Number.MAX_VALUE};
	show: boolean = false;

  terminalVisit: TerminalVisit;
  stevedores: Stevedore[] = [];
  vesselServices: VesselService[] = [];
  terminals: Terminal[] = [];

  terminalNameFormatter = (terminal: Terminal) => terminal ? terminal.terminalCode + ' - ' + terminal.terminalName : '';
  vesselServiceNameFormatter = (vesselService: VesselService) => vesselService
    ? vesselService.code + ' - ' + vesselService.name
    : this.berthVisit.vesselServiceCode + ' - ' + this.berthVisit.vesselServiceName;

  oldMooring: Mooring;
  oldBollards = null;

  constructor() {
	}

  ngOnInit() {
		if(this.bothBollardsEntered() && !(this.berthVisit.ata || this.berthVisit.atd)) {
			this.updateBollardsNotInOrder()
			this.updateBollardRange(() => {})
		}

    if (this.context.visit.terminalPlanningEnabled || AppContext.showPortCallOptimization()) {
      this.terminalVisit = this.context.getTerminalVisit(this.berthVisit);
    }

    if (this.context.visit.terminalPlanningEnabled) {
      sendQuery('com.portbase.bezoekschip.common.api.visit.GetStevedores', <GetStevedores>{})
        .subscribe((result: Stevedore[]) => {
          this.stevedores = result;

          // check if we are still in a visit
          if (this.context.visit?.terminalPlanningEnabled && this.berthVisit.stevedore?.shortName) {
            const selectedStevedore: Stevedore = this.stevedores.filter(p => p.shortName === this.berthVisit.stevedore.shortName)[0];
            if (selectedStevedore) {
              this.setStevedore(selectedStevedore);
            }
          }
        });
    }
  }

  berthChanged(): void {
    if (this.appContext.showPortCallOptimization()) this.forcePlanningBasedOnFirstBerth();
    this.updateBollardRange(() => {this.setBerthBasedOnBollards()});
  }

  private forcePlanningBasedOnFirstBerth() {
    if (this.firstBerth && this.terminalProvidesPlanning(this.berthVisit.berth?.organisationShortName)) {
      this.planningBasedForcedWarning = this.planningBasedForcedWarning || VisitContext.planningBasedOnPilotBoarding();
      VisitContext.visit.visitDeclaration.portVisit.portEntry.baseForPlanning = BaseForPlanning.FIRST_BERTH;
    }
  }

  terminalProvidesPlanning(shortName: string | undefined) {
    return TerminalsThatProvidePlanning.indexOf(shortName) >= 0;
  }

  planningBasedForcedWarningOff() {
    this.planningBasedForcedWarning = false;
  }

  planningBasedMismatchWarning() {
    return this.appContext.showPortCallOptimization() && this.firstBerth && this.terminalProvidesPlanning(this.berthVisit.berth?.organisationShortName) && VisitContext.planningBasedOnPilotBoarding();
  }

  updateBollardsFrom($event: any) {
    this.berthVisit.bollardFrom = this.roundBollard($event.target.value)
    this.updateBollardsNotInOrder();
    this.setBerthBasedOnBollards();
  }

  updateBollardsTo($event: any) {
    this.berthVisit.bollardTo = this.roundBollard($event.target.value)
    if(this.bothBollardsEntered()) {
			this.updateBollardsNotInOrder();
			this.setBerthBasedOnBollards();
		}
  }

  private updateBollardRange(afterCompletedCallback: () => void) {
    if (this.areBollardsUpdatable()) {
      sendQuery('com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys', {
        term: this.berthVisit.berth.name,
        portUnCode: this.context.visit.portOfCall.port.locationUnCode
      }).subscribe((berths: Berth[]) => {
        if (this.areBollardsUpdatable()) { // check again to avoid a TypeError when user removed the berth
          this.bollardRange = this.calculateTotalBollardRange(berths)
        } else {
          this.bollardRange = {start: 0, end: Number.MAX_VALUE}
        }
        afterCompletedCallback()
      });
    } else {
      this.bollardRange = {start: 0, end: Number.MAX_VALUE}
      afterCompletedCallback()
    }
  }

  areBollardsUpdatable() {
    return this.berthVisit.berth && this.berthVisit.berth.bollards && this.berthVisit.berth.name
      && !this.berthVisit.ata && !this.berthVisit.atd;
  }

  private calculateTotalBollardRange(berths: Berth[]) {
    const visitedBerth = this.berthVisit.berth;
    let range: Bollards = {start: Number.MAX_VALUE, end: 0}

    if (visitedBerth != null) {
      berths.filter(berth => berth.bollards != null && berth.name === visitedBerth.name).forEach(berth => {
        range.start = Math.min(berth.bollards.start, range.start)
        range.end = Math.max(berth.bollards.end, range.end)
      })
    }

    range.start = (range.start == Number.MAX_VALUE) ? 0 : range.start
    range.end = (range.end == 0) ? Number.MAX_VALUE : range.end
    return range;
  }

  private setBerthBasedOnBollards(): void {
    if (this.bothBollardsEntered() && !this.bollardsNotInOrder && this.areBollardsUpdatable()) {
      let centerOfVessel = (+this.berthVisit.bollardTo + +this.berthVisit.bollardFrom) / 2;
      let bollards = this.berthVisit.berth.bollards;
      if (!(centerOfVessel >= bollards.start && centerOfVessel <= bollards.end)) {
        sendQuery('com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys', {
          term: this.berthVisit.berth.name,
          portUnCode: this.context.visit.portOfCall.port.locationUnCode
        }).subscribe((v: Berth[]) => {
          let requiredBerth = v.filter(b => b.bollards != null && b.name === this.berthVisit.berth?.name).find(b => centerOfVessel >= b.bollards.start && centerOfVessel <= b.bollards.end);
					if (!!requiredBerth) {
						if (requiredBerth.code !== this.berthVisit.berth.code) {
							this.suggestedBerth = requiredBerth.code;
							this.berthVisit.berth = requiredBerth;
							this.outsideRange = false;
						} else {
							this.suggestedBerth = null;
							this.outsideRange = false;
						}
					} else {
						this.suggestedBerth = null;
						this.outsideRange = true;
					}
				});
      } else {
				this.outsideRange = false;
				this.suggestedBerth = null;
      }
    }
  }

  private updateBollardsNotInOrder() {
    this.bollardsNotInOrder = this.berthVisit.bollardTo <= this.berthVisit.bollardFrom;
  }

  private areBollardsInRange() {
    return this.isBollardInRange(this.berthVisit.bollardFrom) && this.isBollardInRange(this.berthVisit.bollardTo)
  }

  private isBollardInRange(bollard: number) {
    return this.bollardRange.start <= bollard && bollard <= this.bollardRange.end;
	}
	private bothBollardsEntered() {
    return typeof (this.berthVisit.bollardFrom) == "number" && typeof (this.berthVisit.bollardTo) == "number";
  }

  static isChronological(currentBerth: BerthVisit, nextBerth: BerthVisit): boolean {
		if (currentBerth && nextBerth) {
			const date = currentBerth.atd || currentBerth.etd || currentBerth.ata || currentBerth.eta;
			const nextDate = nextBerth.ata || nextBerth.eta || nextBerth.atd || nextBerth.etd;
			if (date && nextDate) {
				return moment(nextDate).isSameOrAfter(moment(date));
			}
		}
		return true;
	}

	get lastMovement(): LocalMovement {
		let movement = this.context.visit.visitDeclaration.portVisit.firstMovement;
		for (let visit of this.context.visit.visitDeclaration.portVisit.berthVisits) {
			if (visit == this.berthVisit) {
				break;
			} else {
				movement = visit.nextMovement;
			}
		}
		return movement;
	}

	showAgentTransfer() {
		return !VisitContext.isOrganisationNextDeclarant() && this.berthVisit.berth && this.lastBerth;
	}

	roundBollard = (value: number): number => {
		if (value) {
			value = Math.round(2 * value) / 2;
		}
		return value;
	};

	moorings: Mooring[] = [Mooring.PORT_SIDE, Mooring.STARBOARD_SIDE, Mooring.ALONGSIDE_OTHER_SHIP,
    Mooring.NO_PREFERENCE];
	mooringFormatter = (value: Mooring) => {
		switch (value) {
			case 'PORT_SIDE':
				return 'Port side';
			case 'STARBOARD_SIDE':
				return 'Starboard side';
			case 'ALONGSIDE_OTHER_SHIP':
				return 'Alongside other ship';
			case 'NO_PREFERENCE':
				return 'No preference';
			default:
				throw Error('Unknown mooring value: ' + value);
		}
	};

  terminalMoorings: TerminalMooring[] = [TerminalMooring.PORTSIDE, TerminalMooring.STARBOARD];
  terminalMooringFormatter = (value: TerminalMooring) => {
      switch(value) {
        case TerminalMooring.STARBOARD:
          return "Starboard side";
        case TerminalMooring.PORTSIDE:
          return "Port side";
        default:
          throw Error("Unknown terminal mooring value: " + value);
      }
  }

	visitPurposes: PurposeCode[] = [PurposeCode.DISCHARGE, PurposeCode.LOADING, PurposeCode.AWAITING_ORDERS,
    PurposeCode.BUNKERING, PurposeCode.CARGO_TANK_CLEANING, PurposeCode.CREW_MOVEMENT,
    PurposeCode.CRUISE_LEISURE_RECREATION, PurposeCode.CUSTOMS_CLEARANCE, PurposeCode.DE_GASSING,
    PurposeCode.DISCHARGE_CRUDE_OIL, PurposeCode.DISPOSAL_OF_WASTE, PurposeCode.DRY_DOCK, PurposeCode.GOODWILL_VISIT,
    PurposeCode.LAID_UP, PurposeCode.PASSENGER_MOVEMENT, PurposeCode.QUARANTINE_INSPECTION, PurposeCode.REFUGE,
    PurposeCode.REPAIRS, PurposeCode.TAKING_SUPPLIES, PurposeCode.UNDER_GOVERNMENT_ORDER];
	visitPurposeFormatter = (value: PurposeCode) => {
		switch (value) {
			case 'DISCHARGE':
				return 'Discharge';
			case 'LOADING':
				return 'Loading';
			case 'AWAITING_ORDERS':
				return 'Awaiting orders';
			case 'BUNKERING':
				return 'Bunkering';
			case 'CARGO_TANK_CLEANING':
				return 'Cargo tank cleaning';
			case 'CREW_MOVEMENT':
				return 'Crew movement';
			case 'CRUISE_LEISURE_RECREATION':
				return 'Cruise, leisure and recreation';
			case 'CUSTOMS_CLEARANCE':
				return 'Customs clearance';
			case 'DE_GASSING':
				return 'De-gassing';
			case 'DISCHARGE_CRUDE_OIL':
				return 'Discharge crude oil';
			case 'DISPOSAL_OF_WASTE':
				return 'Disposal of waste';
			case 'DRY_DOCK':
				return 'Dry-dock';
			case 'GOODWILL_VISIT':
				return 'Goodwill visit';
			case 'LAID_UP':
				return 'Laid-up';
			case 'PASSENGER_MOVEMENT':
				return 'Passenger movement';
			case 'QUARANTINE_INSPECTION':
				return 'Quarantine inspection';
			case 'REFUGE':
				return 'Refuge';
			case 'REPAIRS':
				return 'Repairs';
			case 'TAKING_SUPPLIES':
				return 'Taking supplies';
			case 'UNDER_GOVERNMENT_ORDER':
				return 'Under government order';
			default:
				throw Error('Unknown purpose of visit value: ' + value);
		}
	};

  berthVisitNotChronological() {
    return BerthVisitDetailsComponent.berthVisitNotChronological(this.berthVisit);
  }

	static berthVisitNotChronological(berthVisit: BerthVisit) {
		return berthVisit.eta
			&& berthVisit.etd
			&& !berthVisit.ata
			&& !berthVisit.atd
			&& moment(berthVisit.eta).isSameOrAfter(moment(berthVisit.etd));
	}

	previousBerthVisitNotChronological() {
    return BerthVisitDetailsComponent.previousBerthVisitNotChronological(this.firstBerth, this.berthVisit, this.terminalVisit);
  }

  static previousBerthVisitNotChronological(firstBerth: boolean, berthVisit: BerthVisit, terminalVisit: TerminalVisit) {
    // Terminal planning has the automatic re-order confirmation popup
    let visit = VisitContext.visit;
    if (visit.terminalPlanningEnabled) {
      return false;
    }
		//TODO fix this: transfer is now broken
		if (firstBerth) {
			return false;
		}
		if (berthVisit.ata || berthVisit.atd) {
			return false;
		}
		const date = berthVisit.eta ? berthVisit.eta : berthVisit.etd;
		let berthVisits = VisitContext.isOrganisationNextDeclarant() ? visit.nextVisitDeclaration.nextBerthVisits : visit.visitDeclaration.portVisit.berthVisits;
		const previousBerthVisit = berthVisits[berthVisits.indexOf(berthVisit) - 1];
		if (!previousBerthVisit) {
			return false;
		}
    if (berthVisit.terminalPlanningEnabled && previousBerthVisit.terminalPlanningEnabled
      && (terminalVisit && !terminalVisit.status.acceptedByAgent)) {
      const previousTerminalVisit = VisitContext.getTerminalVisit(previousBerthVisit);
      // Multi berth, always allow since both have the same times
      if (previousTerminalVisit.contextCallId === terminalVisit.contextCallId) {
        return false;
      }
    }
		const previousDate = previousBerthVisit.atd ? previousBerthVisit.atd :
			previousBerthVisit.etd ? previousBerthVisit.etd :
				previousBerthVisit.ata ? previousBerthVisit.ata : previousBerthVisit.eta;
		return date && previousDate && moment(previousDate).isSameOrAfter(moment(date))
	}

	get requiredForOrder(): boolean {
		return (this.firstBerth || this.lastMovement?.order) && !this.berthVisit.ata && !this.context.hasDeparted();
	}

  setStevedore = (stevedore: Stevedore) => {
    this.berthVisit.stevedore = stevedore;

    sendQuery('com.portbase.bezoekschip.common.api.visit.GetTerminalsOfOrganisation',
      <GetTerminalsOfOrganisation>{
        portUnCode: VisitContext.visit.portOfCall.port.locationUnCode,
        organisationShortName: stevedore.shortName
      }).subscribe(t => this.terminals = t);

    if (!this.berthVisit.callId) {
      setTimeout(() => {
        VisitContext.getNextCallId().subscribe(value => this.berthVisit.callId = value.toString());
      }, 0);
    }

    sendQuery('com.portbase.bezoekschip.common.api.visit.GetVesselServices',
      <GetVesselServices>{
        stevedoreOrganisationShortName: stevedore.shortName,
        carrierOrganisationShortName: VisitContext.visit.owner.shortName
      }, {
        caching: false,
        showSpinner: false
      })
      .subscribe((result: VesselService[]) => {
        this.vesselServices = result;
        this.berthVisit.vesselServiceCode = result.some(r => r.code === this.berthVisit.vesselServiceCode) ? this.berthVisit.vesselServiceCode : undefined;
        this.berthVisit.vesselServiceName = this.berthVisit.vesselServiceCode !== undefined ? this.berthVisit.vesselServiceName : undefined;
      });
  }

  setVesselService = (vesselService: VesselService) => {
    this.berthVisit.vesselServiceCode = vesselService.code;
    this.berthVisit.vesselServiceName = vesselService.name;
  }

  setTerminal = (terminal: Terminal) => {
    this.berthVisit.terminal = terminal;
  }

  codeToVesselService(vesselServiceCode: string): VesselService {
    return this.vesselServices.filter(v => v.code === vesselServiceCode)[0];
  }

  codeToStevedore(organisationCode: string): Stevedore {
    return this.stevedores.filter(v => v.shortName === organisationCode)[0];
  }

  codeToTerminal(terminalCode: string): Terminal {
    return this.terminals.find(t => t.terminalCode === terminalCode);
  }

  formatDate(date: string) {
    return BerthVisitDetailsComponent.formatDate(date);
  }

  static formatDate = (dateString: string): string => !!dateString ? (moment().isSame(dateString, 'day')
    ? 'Today, ' + moment(dateString).format('HH:mm') : moment(dateString).format('ddd D MMM YYYY, HH:mm')) : "";

  get canApprovePlanning(): boolean {
    return (AppContext.isTerminalPlanner() || AppContext.isVisitDeclarant()) && this.terminalVisit?.status && this.terminalVisit.status.status === 'ACCEPTED' &&
      this.terminalVisit.status.acknowledged && !this.terminalVisit.status.acceptedByAgent;
  }

  approvePlanning(): void {
    sendCommand('com.portbase.bezoekschip.common.api.visit.terminal.AcceptTerminalPlanningForBerth', <AcceptTerminalPlanningForBerth>{
      crn: VisitContext.visit.crn,
      berthVisitId: this.berthVisit.id
    }, () => {
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The terminal planning was approved successfully.');
    });
  }

  get quarterHourEta() {
    return !this.terminalVisit?.info?.rta || this.etaMatchesRta()
      ? this.berthVisit.eta
      : this.terminalVisit.info.rta;
  }

  get quarterHourEtd() {
    return !this.terminalVisit?.info?.etc || VisitContext.isBetweenQuarterRange(this.berthVisit.etd,
      this.terminalVisit?.info?.etc) ? this.berthVisit.etd : this.terminalVisit.info.etc;
  }

  etaMatchesRta() {
    return VisitContext.rtaMatchesEta(this.berthVisit, this.terminalVisit);
  }

  showUseTerminalMooringCheckbox() {
    return AppContext.showPortCallOptimization() && this.terminalVisit && this.mooringUnequal ||
      !!this.oldMooring;
  }

  showUseTerminalBollards() {
    return AppContext.showPortCallOptimization() && this.terminalVisit?.info?.bollardFore && !this.bollardsFromTerminalSame() ||
      !!this.oldBollards;
  }

  get terminalPlanningActive() {
    return !this.terminalVisit?.status.cancelled;
  }

  get getTerminalVisitBollardFrom() {
    return Math.min(this.terminalVisit.info.bollardFore, this.terminalVisit.info.bollardAft);
  }

  get getTerminalVisitBollardTo() {
    return Math.max(this.terminalVisit.info.bollardFore, this.terminalVisit.info.bollardAft);
  }

  get callId() {
    return this.terminalVisit?.contextCallId || this.berthVisit.callId;
  }

  get useTerminalMooringCheckbox(): boolean {
    return this.mooringEqualToTerminal
  }

  set useTerminalMooringCheckbox(value: boolean) {
    if (value) {
      this.oldMooring = this.berthVisit.mooring;
      if (TerminalMooring.PORTSIDE == this.terminalVisit.info.mooringOrientation) {
        this.berthVisit.mooring = Mooring.PORT_SIDE;
      } else if (TerminalMooring.STARBOARD == this.terminalVisit.info.mooringOrientation) {
        this.berthVisit.mooring = Mooring.STARBOARD_SIDE;
      }
    } else {
      this.berthVisit.mooring = this.oldMooring;
    }
  }

  get mooringEqualToTerminal(): boolean {
    return this.terminalVisit && !this.mooringUnequal;
  }

  get mooringUnequalToTerminal(): boolean {
    return this.terminalVisit && this.mooringUnequal;
  }

  get mooringUnequal(): boolean {
    return this.terminalVisit.info && this.terminalVisit.info.mooringOrientation &&
           !((TerminalMooring.PORTSIDE == this.terminalVisit.info.mooringOrientation && Mooring.PORT_SIDE == this.berthVisit.mooring) ||
           TerminalMooring.STARBOARD == this.terminalVisit.info.mooringOrientation && Mooring.STARBOARD_SIDE == this.berthVisit.mooring);
  }

  get bollardFromUnequalToTerminal(): boolean {
    return this.terminalVisit?.info?.bollardFore &&  this.berthVisit.bollardFrom != this.getTerminalVisitBollardFrom;
  }

  get bollardToUnequalToTerminal(): boolean {
    return this.terminalVisit?.info?.bollardFore && this.berthVisit.bollardTo != this.getTerminalVisitBollardTo;
  }

  private bollardsFromTerminalSame() {
    return !this.bollardFromUnequalToTerminal && !this.bollardToUnequalToTerminal;
  }

  get useTerminalBollardsChecked(): boolean {
    return this.bollardsFromTerminalSame();
  }

  set useTerminalBollardsChecked(value: boolean) {
    if (value) {
      this.oldBollards = { from: this.berthVisit.bollardFrom, to: this.berthVisit.bollardTo };
      this.berthVisit.bollardFrom = this.getTerminalVisitBollardFrom
      this.berthVisit.bollardTo = this.getTerminalVisitBollardTo;
    } else {
      this.berthVisit.bollardFrom = this.oldBollards.from;
      this.berthVisit.bollardTo = this.oldBollards.to;
    }
    this.setBerthBasedOnBollards();
  }
}
