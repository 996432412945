<div class="position-fixed sticky-top w-100" style="left: 0; right: 0; z-index: 15000;">
  <app-alerting></app-alerting>
</div>
<app-overview [overviewFilters]="filters.overviewFilters" [searchFunction]="searchTermFunction" (resultsFound)="renderRecords($event, false)"
              [isEmpty]="isEmpty" [facetNameFormatter]="nameFormatter" [facets]="facets" [otherFacets]="otherFacets"
              (filtersCleared)="clearFilters($event)" (facetFiltersChanged)="facetFiltersChanged($event)"
              (loadNextPage)="loadNextPage()" [quickViews]="quickViews"
              [allSelectedable]="anyRecordSelectable" [(selectionState)]="allSelectionState">
  <div class="header-bar d-flex">
    <button *ngIf="appContext.isCargoDeclarant()" class="btn btn-primary text-decoration-none"
            (click)="createConsignment()">
      <i class="button-icon fa-light fa-plus"></i>
      <span class="ps-2 d-none d-md-inline">New Master B/L</span>
    </button>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-fw fa-light fa-xl fa-file-lines me-1"></i>
      <span>{{masterConsignmentCount}}</span>
    </div>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-kit fa-fw fa-light fa-xl fa-pb-file-line me-1"></i>
      <span>{{houseConsignmentCount}}</span>
    </div>
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-fw fa-light fa-xl fa-container-storage me-1"></i>
      <span>{{equipmentCount}}</span>
    </div>
  </div>
  <div class="overview-items">
    <app-master-consignment-overview-item *ngFor="let consignment of data; trackBy: trackByRecord"
                                          [consignment]="consignment" [term]="filters.overviewFilters.term"
                                          [depth]="0" [selectable]="isSelectable(consignment)" [collapsible]="!!consignment.masterConsignment.equipments?.length"></app-master-consignment-overview-item>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No consignments</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
  <div class="overview-footer">
    <ng-container *ngIf="nrOfSelectedConsignments">
      <div class="row bg-light px-1 px-lg-3 pb-3 gy-3 border-top">
        <div class="col-12 col-md-auto col-lg d-flex align-items-center">
          <span class="sub-text me-2">
            <i class="fa fa-fw fa-light fa-xl fa-file-lines"></i>
          </span>
          <span class="d-inline-flex text-nowrap">{{nrOfSelectedConsignments}} consignment{{nrOfSelectedConsignments > 1 ? 's' : ''}}</span>
        </div>
        <div class="col ms-auto p-0"></div>
        <div class="col-12 col-sm-6 col-md-auto">
          <button type="button" class="btn btn-outline-secondary" (click)="openRegisterArrivalModal()">Register arrival</button>
        </div>
      </div>
    </ng-container>
  </div>
</app-overview>

<ng-template #registerArrivalModalBody>
  <p>
    You are about to register an ata for the following consignments. This will cause these consignments to be registered to STI.
  </p>
  <ul class="row">
    <li class="col-4" *ngFor="let consignment of getSelectedConsignments(); trackBy: trackByRecord">
      {{consignment.masterConsignment.consignmentNumber}}
    </li>
  </ul>
  <div class="row gy-4">
    <app-form-field label="Actual time of arrival" class="col-12" [(value)]="timeOfArrival">
      <app-date-field [(ngModel)]="timeOfArrival" [required]="true"></app-date-field>
    </app-form-field>
  </div>
</ng-template>
