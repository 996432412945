import {Component, OnInit} from '@angular/core';
import {VisitContext} from '../../../../visit-context';
import {AppContext} from "../../../../../app-context";
import {PassageCode} from "@portbase/bezoekschip-service-typescriptmodels";
import {formatDateString} from "../../../../../common/utils";

@Component({
  selector: 'app-port-passage-planning-area',
  templateUrl: './port-passage-planning-area.component.html',
  styleUrls: ['./port-passage-planning-area.component.css']
})
export class PortPassagePlanningAreaComponent implements OnInit {

  context = VisitContext;

  constructor() {
  }

  ngOnInit() {
  }

  get ataPortPassagePlanningArea(): string {
    return VisitContext.visit.visitDeclaration.portVisit.passages?.find(p => p.code === PassageCode.PPA)?.ata;
  }

  get ataLeftPreviousPort(): string {
    return VisitContext.visit.visitDeclaration.portVisit.passages?.find(p => p.code === PassageCode.LEFT_PREVIOUS_PORT)?.ata;
  }
  set ataPortPassagePlanningArea(ata ) {
    // do nothing
  }

  set ataLeftPreviousPort(ata) {
    // do nothing
  }

  get showPortCallOptimization(): boolean {
    return AppContext.showPortCallOptimization();
  }

  get previousPortUnloCode(): string {
    return VisitContext.visit.visitDeclaration.previousPorts.find(p => p.port?.locationUnCode)?.port.locationUnCode
  }

  formatDate = (dateString: string): string => formatDateString(dateString);

  atLeastOneAtaPresent() {
    return this.ataPortPassagePlanningArea != null || this.ataLeftPreviousPort != null;
  }
}
