import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent {
  tableView = TableViewComponent;
  @Input() recordTrackBy: (index: number, record: any) => any;

  @Input() editMode: boolean = false;
  @Input() editable: boolean = true;
  @Input() addText: string = "Add";
  @Input() headerTemplate: TemplateRef<any>;
  @Output() addButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() termUpdated: EventEmitter<string> = new EventEmitter<string>();
  term: string;
  private _fields: TableField[];
  enabledFields: TableField[];

  sortField: TableField;
  sortOrder: "asc" | "desc" = 'asc';
  protected filteredData: any[];
  protected _data: any[];

  trackByName = (index: number, field: TableField) => field.name;

  @Input()
  set data(d: any[]) {
    this._data = d;
  }

  @Input()
  set fields(fields: TableField[]) {
    this._fields = fields;
    this.enabledFields = fields.filter(f => !f.hidden);
  }

  sort(field: TableField): void {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortOrder = 'asc';
    }
    // lodash.orderBy(this.data, [field.name], [this.sortOrder]);
  }

  getValueOfRecord = (record: any, field: TableField): any => {
    const name = field.name?.split(".") || [];
    let val = record;
    name.forEach(k => {
      if (val) {
        val = val[k];
      }
    });
    return field.formatter ? field.formatter(val) : field.type === "action" && !val ? record : val;
  }

  protected updateFiltered = (d: any[]) => {
    this.filteredData = d;
  }

  static onFieldChanged = (record: any, field: TableField, value: any) => {
    const name = field.name?.split(".") || [];
    const lastKey = name[name.length - 1];
    let parentObject = record;
    name.slice(0, -1).forEach(k => {
      if (parentObject) {
        parentObject = parentObject[k];
      }
    });
    if (parentObject) {
      parentObject[lastKey] = value;
    }
  }
}

export interface TableField {
  type: string | 'action';
  name?: string;
  header?: string;
  editable?: boolean;
  required?: boolean;
  hidden?: boolean;
  formatter?: (value: any) => any;
  template?: TemplateRef<any>;
  editTemplate?: TemplateRef<any>;
  cellClass?: string;
}
